<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title
        ><h4 class="font-weight-light">{{ current_page }}</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md5>
            <v-select
              v-if="this.remittances_category === 'BOD CHARGE'"
              v-model="type_of_deduction"
              :items="['AUTO LOAN','CA','LOAN','OTHER RECEIVABLE','MOTOR FINANCE','OFFICE RENTAL SEC DEPOSIT','DAMAYAN SERVICE']"
              class="mx-2"
              dense
              outlined
              label="Type of Deductions"
              :rules="rules.combobox_rule"
              @change="initialiaze_data(type_of_deduction,1)"
            ></v-select>
            <v-tabs
              v-model="tab"
              show-arrows
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.title"
              >
                <v-icon
                  size="20"
                  class="me-3"
                >
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-data-table dense
                              :headers="remittances_category === 'CA' ? headers5 :(remittances_category === 'OFFICE RENTAL SEC DEPOSIT'?headers7:headers) "
                              :items="data_auto_items">
                  <template v-slot:item="{ item }">
                    <tr @click="activerow_auto(item)"
                        :class="{ 'info white--text': item.id === selectedDepositId_auto }">
                      <td v-if="remittances_category === 'CA'">
                        {{ item.request_data.cv != null?'CV#' + item.request_data.cv.voucher_no :
                        (item.request_data.ckv != null?'CKV#' + item.request_data.ckv.voucher_no
                        :'')}}
                      </td>
                      <td>
                        {{
                        remittances_category === 'AUTO LOAN'
                        ? item.auto_loan != null
                        ? item.auto_loan.name
                        : item.credit_to
                        : remittances_category === 'CA'
                        ? item.request_data != null
                        ? item.request_data.payment_for
                        : item.credit_to
                        : remittances_category === 'LOAN'
                        ? item.loan_application != null
                        ? item.loan_application.employee != null
                        ? item.loan_application.employee.last_name +
                        ', ' +
                        item.loan_application.employee.first_name +
                        ' ' +
                        item.loan_application.employee.middle_name
                        : item.loan_application.name
                        : item.credit_to
                        : remittances_category === 'OTHER RECEIVABLE'
                        ? item.other_receivable != null
                        ? item.other_receivable.employee != null
                        ? item.other_receivable.employee.last_name +
                        ', ' +
                        item.other_receivable.employee.first_name +
                        ' ' +
                        item.other_receivable.employee.middle_name
                        : item.other_receivable.name
                        : item.credit_to
                        : remittances_category === 'MOTOR FINANCE'
                        ? item.motor_finance != null
                        ? item.motor_finance.employee != null
                        ? item.motor_finance.employee.last_name +
                        ', ' +
                        item.motor_finance.employee.first_name +
                        ' ' +
                        item.motor_finance.employee.middle_name
                        : item.motor_finance.name
                        : item.credit_to
                        : item.credit_to
                        }}
                      </td>
                      <td>
                        {{ remittances_category === 'AUTO LOAN'?
                        (item.auto_loan===null?'RETURN FUND':item.auto_loan.unit)
                        :(remittances_category ==='CA'?
                        (item.request_data===null?'RETURN FUND':item.request_data.description):
                        (remittances_category === 'LOAN'?
                        (item.loan_application===null?'RETURN FUND':item.loan_application.purpose):
                        (remittances_category === 'OTHER RECEIVABLE'?
                        (item.other_receivable===null?'RETURN FUND':item.other_receivable.purpose):
                        (remittances_category === 'MOTOR FINANCE'?
                        (item.motor_finance===null?'RETURN FUND':item.motor_finance.purpose):''))))
                        }}
                      </td>
                      <td>
                        {{ formatPrice(item.amount) }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  dense
                  :headers="remittances_category === 'CA' ? headers4 :(remittances_category === 'OFFICE RENTAL SEC DEPOSIT'?headers6:headers3) "
                  :items="data_items"
                  :search="search"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Data</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>

                      <v-text-field
                        class="mx-2"
                        :append-icon="icons.mdiAccountSearch"
                        label="Search"
                        single-line
                        hide-details
                        v-model="search"
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr @click="activerow(item)"
                        :class="{ 'info white--text': item.id === selectedDepositId }">
                      <td>
                        {{ item.id }}
                      </td>
                      <td v-if="remittances_category === 'CA'">
                        {{ item.voucher_no }}
                      </td>
                      <td>
                        {{ item.name }}
                      </td>
                      <td>
                        {{ item.details }}
                      </td>
                      <td>
                        {{ formatPrice(item.balance) }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-flex>
          <v-flex xs12 md2>
            <v-form ref="form" class="multi-col-validation">
              <v-select
                v-model="deposit_slip_id"
                class="mx-2"
                dense
                outlined
                label="Deposit Slip"
                :items="deposit_slip_items"
                item-value="id"
                item-text="data"
                :rules="rules.combobox_rule"
                @change="deposit_slip_selected"
              ></v-select>
              <v-text-field
                class="mx-2"
                v-model="date_deposited"
                label="Date Deposited"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
              <v-text-field
                class="mx-2"
                v-model="time_deposited"
                label="Time Deposited"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
              <v-text-field
                class="mx-2"
                v-model="bank_code"
                label="Bank"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
              <v-text-field
                class="mx-2"
                v-model="dep_balance"
                label="Dep Balance"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
              <v-select
                v-if="!this.is_automatic"
                v-model="month_of_id"
                class="mx-2"
                dense
                outlined
                label="Month Of"
                :items="month_of_items"
                item-value="id"
                item-text="month_of"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-select
                v-if="!this.is_automatic"
                v-model="particulars"
                class="mx-2 mt-2"
                dense
                outlined
                label="Particulars"
                :items="[
                  'BM Incentives',
                  'BOD Allowance',
                  'BOD Share',
                  'Callers',
                  'CM Incentives',
                  'Others',
                  'Production Incentives',
                  'RM Incentives',
                  'Salary',
                  'VP Allowance',
                  'TH Incentives',
                ]"
                :rules="rules.combobox_rule"
                @change="selected_particulars"
              ></v-select>
              <v-text-field
                v-if="particulars === 'Others'"
                class="mx-2"
                v-model="specify_others"
                label="Specify"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
              <v-select
                v-if="particulars === 'Salary'"
                v-model="duration"
                class="mx-2 mt-2"
                dense
                outlined
                label="Duration"
                :items="duration_items"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-text-field
                v-if="!this.is_automatic"
                class="mx-2"
                v-model="amount"
                label="Amount"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
                min="0"
              ></v-text-field>
              <v-checkbox v-if="!this.is_automatic" v-model="is_return_fund" label="Is Return Fund?"
                          hide-details
                          class="me-3 mt-1"></v-checkbox>
              <v-text-field
                v-if="is_return_fund"
                class="mx-2"
                v-model="description"
                label="Description"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
              <v-text-field
                v-if="is_return_fund"
                class="mx-2"
                v-model="payment_for"
                label="Payment For"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
              <v-text-field
                v-if="is_return_fund"
                class="mx-2"
                v-model="credit_to"
                label="Credit to"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
              <v-col cols="12" v-show="alert">
                <v-alert color="warning" text class="mb-0">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12" v-if="is_can_proceed">
                <v-btn class="w-full" color="primary" @click="save_payment()" v-if="!saving_data">
                  Save
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
            </v-form>
          </v-flex>
          <v-flex xs12 md5 class="mx-2">
            <h2>Total: P {{ total_amount }}</h2>
            <v-data-table dense
                          :headers="remittances_category === 'CA' ? headers5 :(remittances_category === 'OFFICE RENTAL SEC DEPOSIT'?headers7:headers) "
                          :items="data_items2">
              <template v-slot:item="{ item }">
                <tr>
                  <td v-if="remittances_category === 'CA'">
                    {{ item.request_data.cv != null?'CV#' + item.request_data.cv.voucher_no :
                    (item.request_data.ckv != null?'CKV#' + item.request_data.ckv.voucher_no :'')}}
                  </td>
                  <td>
                    {{
                    remittances_category === 'AUTO LOAN'
                    ? item.auto_loan != null
                    ? item.auto_loan.name
                    : item.credit_to
                    : remittances_category === 'CA'
                    ? item.request_data != null
                    ? item.request_data.payment_for
                    : item.credit_to
                    : remittances_category === 'LOAN'
                    ? item.loan_application != null
                    ? item.loan_application.employee != null
                    ? item.loan_application.employee.last_name +
                    ', ' +
                    item.loan_application.employee.first_name +
                    ' ' +
                    item.loan_application.employee.middle_name
                    : item.loan_application.name
                    : item.credit_to
                    : remittances_category === 'OTHER RECEIVABLE'
                    ? item.other_receivable != null
                    ? item.other_receivable.employee != null
                    ? item.other_receivable.employee.last_name +
                    ', ' +
                    item.other_receivable.employee.first_name +
                    ' ' +
                    item.other_receivable.employee.middle_name
                    : item.other_receivable.name
                    : item.credit_to
                    : remittances_category === 'MOTOR FINANCE'
                    ? item.motor_finance != null
                    ? item.motor_finance.employee != null
                    ? item.motor_finance.employee.last_name +
                    ', ' +
                    item.motor_finance.employee.first_name +
                    ' ' +
                    item.motor_finance.employee.middle_name
                    : item.motor_finance.name
                    : item.credit_to
                    : item.credit_to
                    }}
                  </td>
                  <td>
                    {{ remittances_category === 'AUTO LOAN'?
                    (item.auto_loan===null?'RETURN FUND':item.auto_loan.unit)
                    :(remittances_category ==='CA'?
                    (item.request_data===null?'RETURN FUND':item.request_data.description):
                    (remittances_category === 'LOAN'?
                    (item.loan_application===null?'RETURN FUND':item.loan_application.purpose):
                    (remittances_category === 'OTHER RECEIVABLE'?
                    (item.other_receivable===null?'RETURN FUND':item.other_receivable.purpose):
                    (remittances_category === 'MOTOR FINANCE'?
                    (item.motor_finance===null?'RETURN FUND':item.motor_finance.purpose):''))))
                    }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount) }}
                  </td>
                  <td class="text-center">
                    <v-icon class="mr-2" color="error" @click="delete_payment(item)"
                            v-if="!deleteing">
                      {{ icons.mdiDeleteForever }}
                    </v-icon>
                    <v-progress-circular :size="25" :width="5" color="success" indeterminate
                                         v-else></v-progress-circular>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiDeleteForever, mdiGoogleCirclesCommunities
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      alert: false,
      saving_data: false,
      alert_message: '',

      is_return_fund: false,
      search: '',
      month_of_id: '',
      particulars: '',
      bank_code: '',
      specify_others: '',
      time_deposited: '',
      date_deposited: '',
      type_of_deduction: '',
      description: '',
      payment_for: '',
      credit_to: '',
      amount: '',
      total_amount: '0',
      dep_balance: '0',
      deposit_slip_id: '',
      deposit_slip_items: [],
      tab: '',
      tabs: [
        {title: 'AUTO', icon: mdiGoogleCirclesCommunities},
        {title: 'MANUAL', icon: mdiAccountOutline},
      ],
      headers: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Details', value: 'name', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
      ],
      headers2: [
        {text: 'Name', value: 'date_of_deposit', sortable: false},
        {text: 'Description', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Particulars', value: 'tin', sortable: false},
        {text: 'Duration', value: 'tin', sortable: false},
      ],
      headers3: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Details', value: 'name', sortable: false},
        {text: 'Balance', value: 'date_of_deposit', sortable: false},
      ],
      headers4: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Voucher#', value: 'voucher_no', sortable: false},
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Details', value: 'name', sortable: false},
        {text: 'Balance', value: 'date_of_deposit', sortable: false},
      ],
      headers5: [
        {text: 'Voucher #', value: 'name', sortable: false},
        {text: 'Name', value: 'tin', sortable: false},
        {text: 'Details', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
      ],
      headers6: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Branch', value: 'branch', sortable: false},
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Details', value: 'name', sortable: false},
        {text: 'Balance', value: 'date_of_deposit', sortable: false},
      ],
      headers7: [
        {text: 'Branch', value: 'branch', sortable: false},
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Details', value: 'name', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
      ],
      data_items: [],
      data_items2: [],
      data_auto_items: [],
      month_of_items: [],
      selectedDepositId: -1,
      selectedDepositId_auto: -1,
      current_page: '',
      remittances_category: '',
      receivable_info: {},
      receivable_info_auto: {},
      deposit_info: {},
      duration: 'NA',
      duration_items: [],
      is_can_proceed: false,
      is_automatic: false,
      deleteing: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiDeleteForever,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('deposit_slip_receivable', ['initialize_company_receivable_payments', 'save_receivable_payments', 'update_receivable_payments', 'delete_receivable_payments', 'initialize_company_receivable_payments_automatic']),
      initialize_data() {
        switch (this.$router.currentRoute.name) {
          case 'company-receivable-auto-loan-payment':
            this.current_page = 'PAYMENT OF (AUTO LOAN)'
            this.remittances_category = 'AUTO LOAN'
            break
          case 'company-receivable-cash-advance-payment':
            this.current_page = 'PAYMENT OF (CASH ADVANCE)'
            this.remittances_category = 'CA'
            break
          case 'company-receivable-loan-payment':
            this.current_page = 'PAYMENT OF (ACCOUNTING LOAN)'
            this.remittances_category = 'LOAN'
            break
          case 'company-receivable-other-receivable-payment':
            this.current_page = 'PAYMENT OF (OTHER RECEIVABLE)'
            this.remittances_category = 'OTHER RECEIVABLE'
            break
          case 'company-receivable-motor-finance-payment':
            this.current_page = 'PAYMENT OF (MOTOR FINANCE)'
            this.remittances_category = 'MOTOR FINANCE'
            break
          case 'company-receivable-office-sec-payment':
            this.current_page = 'PAYMENT OF (OFFICE RENTAL SEC DEPOSIT)'
            this.remittances_category = 'OFFICE RENTAL SEC DEPOSIT'
            break
          case 'company-receivable-bod-charge-payment':
            this.current_page = 'PAYMENT OF (BOD CHARGES)'
            this.remittances_category = 'BOD CHARGE'
            break
        }
        if (this.remittances_category === 'BOD CHARGE') {
          this.initialiaze_data(this.remittances_category, 1)
        } else {
          this.initialiaze_data(this.remittances_category, 0)
        }
      },
      initialiaze_data(data, is_bod_charge) {
        this.initialize_company_receivable_payments({
          category: data,
          is_bod_charge: is_bod_charge,
        })
          .then(response => {
            this.month_of_items = response.data[0].month_of_items
            this.deposit_slip_items = response.data[0].deposit_available
            this.data_items = response.data[0].data
          })
          .catch(error => {
            console.log(error)
          })
        if (data!='OFFICE RENTAL SEC DEPOSIT' && !is_bod_charge){
          this.initialize_company_receivable_payments_automatic({
            category: data,
          })
            .then(response => {
              this.data_auto_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      delete_payment(item) {
        this.deleteing = true
        const data = new FormData()
        data.append('category', this.remittances_category)
        data.append('id', item.id)
        this.delete_receivable_payments(data)
          .then(response => {
            this.saving_data = false
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            var ssee = this.search
            var moo = this.month_of_id
            var parr = this.particulars
            Object.assign(this.$data, initialState())
            this.initialize_data()
            this.search = ssee
            this.month_of_id = moo
            this.particulars = parr
            this.selected_particulars(parr)
          })
          .catch(error => {
            console.log(error)
          })
      },
      deposit_slip_selected() {
        var index = this.deposit_slip_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.deposit_slip_id)

        if (index != -1) {
          var tto = 0
          this.deposit_info = this.deposit_slip_items[index]
          this.date_deposited = this.deposit_info.date
          this.time_deposited = this.deposit_info.time
          this.bank_code = this.deposit_info.bank.bank_code
          this.is_can_proceed = this.deposit_info.balance > 0
          this.dep_balance = this.formatPrice(this.deposit_info.balance)

          this.data_items2 = this.deposit_info.payment
          if (this.data_items2.length > 0) {

            this.data_items2.forEach(function (item) {
              tto += parseFloat(item.amount)
            })
          }
          this.total_amount = this.formatPrice(tto)
        }
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_particulars(value) {
        if (value === 'Salary') {
          this.duration = ''
          this.duration_items = ['1-15', '16-30/31']
        } else {
          this.duration = 'NA'
          this.duration_items = ['NA']
        }
      },
      activerow(value) {
        this.is_automatic = false
        this.selectedDepositId = value.id
        this.receivable_info = value
      },
      activerow_auto(value) {
        this.is_automatic = true
        this.selectedDepositId_auto = value.id
        this.receivable_info_auto = value
      },
      save_payment() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if ((this.selectedDepositId === -1 && this.selectedDepositId_auto === -1) && !this.is_return_fund) {
            this.saving_data = false
            this.change_snackbar({
              show: true,
              color: 'error',
              text: 'Please Select Data First!!!',
            })
          } else {
            const data = new FormData()
            data.append('deposit_slip_id', this.deposit_info.id)
            data.append('category_id', this.selectedDepositId === -1 ? '' : this.selectedDepositId)
            data.append('category', this.remittances_category === 'BOD CHARGE' ? this.type_of_deduction : this.remittances_category)
            data.append('duration', this.duration)
            data.append('description', this.description.toUpperCase())
            data.append('payment_for', this.payment_for.toUpperCase())
            data.append('credit_to', this.credit_to.toUpperCase())
            data.append(
              'particulars',
              this.particulars === 'Others' ? this.specify_others.toUpperCase() : this.particulars,
            )
            data.append('amount', this.amount)
            if (this.is_automatic) {
              data.append('payment_id', this.selectedDepositId_auto)
              this.update_receivable_payments(data)
                .then(response => {
                  this.saving_data = false
                  var color = 'success'
                  if (response.status === 201) {
                    color = 'error'
                  }
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: response.data,
                  })
                  var ssee = this.search
                  var moo = this.month_of_id
                  var parr = this.particulars
                  Object.assign(this.$data, initialState())
                  this.initialize_data()
                  this.search = ssee
                  this.month_of_id = moo
                  this.particulars = parr
                  this.selected_particulars(parr)
                })
                .catch(error => {
                  console.log(error)
                })
            } else {
              this.save_receivable_payments(data)
                .then(response => {
                  this.saving_data = false
                  var color = 'success'
                  if (response.status === 201) {
                    color = 'error'
                  }
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: response.data,
                  })
                  var ssee = this.search
                  var moo = this.month_of_id
                  var parr = this.particulars
                  Object.assign(this.$data, initialState())
                  this.initialize_data()
                  this.search = ssee
                  this.month_of_id = moo
                  this.particulars = parr
                  this.selected_particulars(parr)
                })
                .catch(error => {
                  console.log(error)
                })
            }
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
